<template>
  <div>
    <tableComponent></tableComponent>
  </div>
</template>

<script>
  import tableComponent from "./tableComponent.vue";

  export default {
    components: {
      tableComponent,
    },
  };
</script>

<style scoped></style>
